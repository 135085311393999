import { useEffect, useRef } from 'react';

type Props = {
  handler(isIntersecting: boolean): void;
  threshold?: number;
  rootMargin?: string;
};

export const useObserver = ({ handler, threshold: thresholdGiven, rootMargin: rootMarginGiven }: Props) => {
  const root = null,
    rootMargin = rootMarginGiven ?? '0px',
    threshold = thresholdGiven ?? 0.1;
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        handler(entry.isIntersecting);
      },
      {
        root: root, // null means the viewport
        rootMargin: rootMargin,
        threshold: threshold, // trigger when 10% of the element is visible
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef, handler]);

  return { observerRef };
};
