import { useNavigate } from 'react-router-dom';
import { useGetMiniAppInitData } from './getInitData';
import { useEffect } from 'react';
import { useGetIsAuth } from 'hooks';

/**
 * Navigate unauth user to auth via ref route, if refCode exists.
 * Navigates to shop if startParam equals to 'navToShop' (made for test, left here as example).
 * No more functionality for now.
 */

export const useHandleMiniAppStartParam = () => {
  const { startParam } = useGetMiniAppInitData();
  const isAuth = useGetIsAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (startParam) {
      if (startParam.includes('refCode_') && !isAuth) {
        const refCode = startParam.replace('refCode_', '');
        navigate(`/ref/${refCode}`);
      }
      if (startParam.includes('navToShop')) {
        navigate('/shop');
      }
    }
  }, [startParam, isAuth]);
};
