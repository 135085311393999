export interface ILogInForm {
  email: string;
  password: string;
}

export interface ILogInWithMoralis {
  address: string;
}

export interface ISignUpForm {
  email: string;
  password: string;
  confirm_password: string;
}

export interface IAuthTokens {
  refreshToken: string;
  accessToken: string;
}

export interface ISendCodeForm {
  email: string;
}
export interface ICheckCodeForm {
  resetCode: string;
}
export interface ISetNewPasswordForm {
  password: string;
  confirm_password: string;
}

export interface IResetPasswordBody {
  email?: string;
  resetCode?: string;
  password?: string;
}

export interface ILogInResponse extends IAuthTokens {
  user: any;
  message: string;
}

export enum EAuthMethods {
  Email = 'email',
  Google = 'google',
  Metamask = 'metamask',
  TON = 'TON',
  EVM = 'EVM',
  Telegram = 'telegram',
}
