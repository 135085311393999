import { useEffect, useState } from 'react';
import { FastAverageColorOptions } from 'fast-average-color';
import getAverageColorByUrl from 'utils/getAverageColorByUrl';

export interface AverageColorOprionsInterface extends FastAverageColorOptions {
  opacity?: string;
}

const useAverageColorFromImageUrl = (url: string, options?: AverageColorOprionsInterface) => {
  const [color, setColor] = useState('');
  useEffect(() => {
    (async () =>
      await getAverageColorByUrl(url, options)
        .then((res) => setColor(res.replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1' + (options && options.opacity ? options?.opacity : 1) + ')')))
        .catch(console.error))();
  }, [url, options]);

  return color;
};

export default useAverageColorFromImageUrl;
