import { ICourse, ICourseFull, IResultCourse } from 'interface/courses';
import { customFetchBase } from 'store/utils';
import {
  IAnswerBody,
  IAnswerResponse,
  IAnswerResponseError,
  IClaimRewardsRequest,
  ICompleteSlideRequest,
  ICompleteSlideResponse,
  ICourseForCompletionFetchResult,
  ICoursesFetchRes,
} from './types';
import { ILeftOffLesson } from 'components/pages/study/courses/left-off-lesson';
import { createApi } from '@reduxjs/toolkit/query/react';

export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  // refetchOnFocus: false,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  tagTypes: ['Course', 'StartedCourses'],
  endpoints: (builder) => ({
    getFullCourse: builder.query<ICourseFull, string>({
      query: (courseId) => {
        return {
          url: `/course/full/${courseId}`,
        };
      },
      keepUnusedDataFor: 100,
    }),
    getCourseById: builder.query<ICourse, string>({
      query: (courseId) => ({
        url: `/course/${courseId}`,
      }),
    }),
    getAllCourses: builder.query<ICoursesFetchRes, Record<string, boolean | string | number | any[]> | undefined>({
      query: (query) => {
        return {
          url: '/course',
          params: query,
        };
      },
    }),
    getCourseForCompletion: builder.query<ICourseForCompletionFetchResult, { courseId: string; chapterId?: string; lessonId?: string }>({
      query: ({ courseId, chapterId, lessonId }) => ({ url: `/course/for-completion/${courseId}/${chapterId}/${lessonId}` }),
    }),
    completeSlide: builder.mutation<ICompleteSlideResponse, ICompleteSlideRequest>({
      query: (data) => {
        return {
          url: '/result-slide/finish',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Course'],
    }),
    checkAnswer: builder.mutation<IAnswerResponse | IAnswerResponseError, IAnswerBody>({
      query: (data) => {
        return {
          url: '/answer/check-answer',
          method: 'PUT',
          body: data,
        };
      },
    }),
    claimRewards: builder.mutation<{ status: number; message: string; resultCourse: IResultCourse }, IClaimRewardsRequest>({
      query: (body) => ({
        url: '/result-slide/claim-rewards',
        method: 'PUT',
        body,
      }),
    }),
    getLeftOffLesson: builder.query<ILeftOffLesson, void>({
      query: () => {
        return {
          url: '/result-lesson/last-lesson',
        };
      },
      keepUnusedDataFor: 100,
    }),
    getLeftOffLessonByCourseId: builder.query<ILeftOffLesson, string>({
      query: (courseId) => {
        return {
          url: `/result-lesson/last-lesson/${courseId}`,
        };
      },
    }),
    getResultCourseByCourseId: builder.query<IResultCourse, { courseId: string }>({
      query: (courseId) => ({
        url: `/result-course/by-course-id/full/${courseId}`,
      }),
    }),
    // dev stuff
    flushCourse: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/result-course/flush/me',
          method: 'PUT',
          body,
        };
      },
    }),
    flushMyCourses: builder.mutation<any, void>({
      query: () => {
        return {
          url: '/result-course/flush/me/all',
        };
      },
      invalidatesTags: ['Course'],
    }),
    getStartedCourses: builder.query<any, any>({
      query: (body) => {
        return {
          url: '/result-course/started',
          method: 'PUT',
          body,
        };
      },
      providesTags: ['StartedCourses'],
    }),
    // Students count

    getStudentsCountByCourse: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-course/students-count/by-course-id/${id}`,
      }),
    }),
    getStudentsCountByChapter: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-chapter/students-count/by-chapter-id/${id}`,
      }),
    }),
    getStudentsCountByLesson: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-lesson/students-count/by-lesson-id/${id}`,
      }),
    }),
    getStudentsCountBySlide: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-slide/students-count/by-slide-id/${id}`,
      }),
    }),
  }),
});
export const {
  useGetCourseByIdQuery,
  useLazyGetAllCoursesQuery,
  useLazyGetFullCourseQuery,
  useCompleteSlideMutation,
  useCheckAnswerMutation,
  useLazyGetLeftOffLessonQuery,
  useLazyGetLeftOffLessonByCourseIdQuery,
  useFlushCourseMutation,
  useFlushMyCoursesMutation,
  useLazyGetResultCourseByCourseIdQuery,
  useLazyGetCourseForCompletionQuery,
  useGetCourseForCompletionQuery,
  useLazyGetStartedCoursesQuery,
  useLazyGetStudentsCountByCourseQuery,
  useLazyGetStudentsCountByChapterQuery,
  useLazyGetStudentsCountByLessonQuery,
  useLazyGetStudentsCountBySlideQuery,
  useClaimRewardsMutation,
} = coursesApi;
