import { createWeb3Modal, defaultConfig, Web3Modal } from '@web3modal/ethers';
import { BrowserProvider } from 'ethers';
import { EAuthMethods } from 'interface';
import { useLazyGenerateNonceQuery, useLazyGetUserDataQuery, useLoginMFAMutation } from 'store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAttachUser, useGetSettings, useUnattachUser } from 'hooks';

interface IProps {
  isAutoInit?: boolean;
}

export const useEVMWalletAuth = ({ isAutoInit = true }: IProps = {}) => {
  const [generateNonce] = useLazyGenerateNonceQuery();
  const [login, { isLoading: isLogingIn }] = useLoginMFAMutation();
  const [getUser, { isFetching: isUserLoading }] = useLazyGetUserDataQuery();
  const { handleAttach: attach, isLoading: isAttaching } = useAttachUser({ method: EAuthMethods.EVM });
  const { handleUnattach, isLoading: inUnattaching } = useUnattachUser({ method: EAuthMethods.EVM });
  const subscriptionRef = useRef<() => void>(() => {});

  const { displayedName, logos } = useGetSettings();
  const [modal, setModal] = useState<Web3Modal | null>(null);

  const initModal = useCallback(async () => {
    if (!modal) {
      if (!logos.small || !displayedName) return null;

      // 1. Get projectId from https://cloud.walletconnect.com

      // 2. Set chains
      const mainnet = {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com',
      };

      // 3. Create your application's metadata object
      const metadata = {
        name: displayedName,
        description: 'Gamified Educational platform fot incentivised learning, bridging the worlds of education and gaming together.',
        url: window.location.origin, // url must match your domain & subdomain
        icons: [logos.small],
      };

      // 4. Create Ethers config
      const ethersConfig = defaultConfig({
        /*Required*/
        metadata,

        /*Optional*/
        enableEIP6963: false, // true by default
        enableInjected: false, // true by default
        enableCoinbase: false, // true by default
        defaultChainId: 1, // used for the Coinbase SDK
        auth: {
          showWallets: true,
          email: false,
        },
      });

      const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

      if (!projectId) {
        throw new Error('REACT_APP_WALLET_CONNECT_PROJECT_ID is required');
      }

      const modal = createWeb3Modal({
        ethersConfig,
        chains: [mainnet],
        projectId: projectId,
      });

      if (!modal) throw Error('Modal is not created');
      modal.setThemeVariables({ '--w3m-z-index': 9999 });
      return modal;
    }
    return null;
  }, [displayedName, logos.small, modal]);

  const handleDisconnect = useCallback(async (givenModal?: typeof modal) => {
    if (givenModal) {
      await givenModal.disconnect();
      await givenModal.close();
    }
  }, []);

  const handleLogin = useCallback(async () => {
    if (!modal) return null;
    try {
      const walletProvider = modal.getWalletProvider();

      if (!walletProvider) throw Error('User disconnected');

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const address = await signer.getAddress();

      const { nonce } = await generateNonce().unwrap();
      const signature = await signer.signMessage(nonce);

      const referralCode = localStorage.getItem('referralCode') || undefined;

      await login({
        type: EAuthMethods.EVM,
        referralCode,
        data: {
          signature,
          nonce,
          address,
        },
      }).then(() => getUser());
    } catch (error) {
      console.error('Login error:', error);
      await handleDisconnect(modal);
    }
  }, [modal, generateNonce, getUser, handleDisconnect, login]);

  const handleAttach = async () => {
    if (!modal) {
      return initModal().then((m) => {
        setModal(m);
        return m;
      });
    }

    const att = async () => {
      try {
        const walletProvider = modal.getWalletProvider();

        if (!walletProvider) throw Error('User disconnected');

        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const address = await signer.getAddress();

        const { nonce } = await generateNonce().unwrap();
        const signature = await signer.signMessage(nonce);

        await attach({
          signature,
          nonce,
          address,
        });
      } catch (error) {
        console.error('EVM wallet attach error:', error);
      } finally {
        await handleDisconnect(modal);
        setModal(null);
      }
    };

    if (!modal.getWalletProvider()) {
      subscriptionRef.current = modal.subscribeEvents((data) => {
        try {
          if (data.data.event === 'CONNECT_SUCCESS') {
            setTimeout(async () => {
              handleAttach();
            }, 500);
          }
        } catch (error) {
          console.error('WalletConnect event error:', error);
        }
      });
      return handleConnectModalOpen();
    } else {
      subscriptionRef.current();
      att();
    }
  };

  useEffect(() => {
    if (isAutoInit && !modal) {
      initModal().then(setModal);
    }
  }, [modal, isAutoInit, initModal]);

  const handleConnectModalOpen = async () => {
    if (modal) {
      if (!modal.getWalletProviderType()) {
        await modal.open({ view: 'Connect' });
      }
    }
  };

  const handleLogOut = async () => {
    if (!modal) {
      await initModal().then(handleDisconnect);
    } else {
      await handleDisconnect(modal);
    }
  };

  const handleUnattachWrapper = async () => handleUnattach().then(() => handleDisconnect(modal));

  const isLoading = isLogingIn || isUserLoading || isAttaching || inUnattaching;
  return {
    isLoading,
    isLogingIn,
    isUserLoading,
    isAttaching,
    inUnattaching,
    modal,
    openConnectModal: handleConnectModalOpen,
    logOut: handleLogOut,
    logIn: handleLogin,
    attach: handleAttach,
    unattach: handleUnattachWrapper,
  };
};
