import { useTonConnectUI } from '@tonconnect/ui-react';
import { useEVMWalletAuth } from 'pages/auth/authButtons/buttons/useEVMWalletAuth';
import { useLogOutMutation } from 'store';

export const useLogout = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [logOut] = useLogOutMutation();
  const { logOut: handleLogOut } = useEVMWalletAuth({ isAutoInit: false });

  const logOutHandler = async () => {
    if (tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
    await handleLogOut();
    await logOut().unwrap().catch(console.error);
  };
  return logOutHandler;
};
