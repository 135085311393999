import { FC, PropsWithChildren } from 'react';
// import { clientDetection } from '../../../utils/clientDetection';
// import { DownloadApp } from '../DownloadApp';

export const DownloadAppProvider: FC<PropsWithChildren> = ({ children }) => {
  // const { isInstalledApp, os, isTelegram, isPartnerApp } = clientDetection();
  // if (!isInstalledApp && (os === 'Android' || os === 'iOS') && !isTelegram && !isPartnerApp && process.env.NODE_ENV === 'production') {
  //   return <DownloadApp />;
  // }

  return <>{children}</>;
};
