import './wdyr';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { persistor, store } from 'store';
import './i18n';
import { GlobalPageAnimation } from './shared/utils/GlobalPageAnimation/GlobalPageAnimation';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'app/app';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

//@ts-ignore
if (window?.Telegram?.WebApp) {
  //@ts-ignore
  window?.Telegram?.WebApp?.expand();
}

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <TonConnectUIProvider manifestUrl={process.env.REACT_APP_TON_MANIFEST_URL} restoreConnection={false}>
        <StrictMode>
          <GlobalPageAnimation />
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </StrictMode>
      </TonConnectUIProvider>
    </Provider>
  </PersistGate>,
  document.getElementById('root')
);
