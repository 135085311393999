import { createApi } from '@reduxjs/toolkit/query/react';

import { customFetchBase } from 'store/utils';

import { TCreateCourseDTO, TParamsEditCourseMutation, TParamsEditImageCourseMutation } from './types';
import { prepareCreateDto, prepareDuplicateDto, prepareUpdateDto } from './utils';
import { IChapterFull, ICourse, ICourseFull, ILessonFull, ISlideFull } from 'interface/courses';
import { ECourseSpaceVisibility } from 'app/constants';
import { spacesApi } from 'store/spaces';

export const coursesConstructorAPI = createApi({
  reducerPath: 'coursesConstructorAPI',
  baseQuery: customFetchBase,
  tagTypes: ['Slides', 'Lessons', 'Chapters', 'Courses'],
  endpoints: (build) => ({
    // Courses
    createCourse: build.mutation({
      query: (dto: TCreateCourseDTO) => ({
        url: 'course',
        method: 'POST',
        body: dto,
      }),
    }),
    editCourse: build.mutation({
      query: (dto: TParamsEditCourseMutation) => ({
        url: `course/${dto.courseId}`,
        method: 'PUT',
        body: dto.body,
      }),
    }),
    editCourseSpaceVisibility: build.mutation({
      query: ({ id, spaceVisibility, spaceId }: { spaceVisibility: ECourseSpaceVisibility; id: string; spaceId?: string }) => ({
        url: `course/space-visibility/${id}`,
        method: 'PUT',
        body: { spaceVisibility, spaceId },
      }),
    }),
    editCourseImage: build.mutation({
      query: (params: TParamsEditImageCourseMutation) => ({
        url: `course/update/image/${params.courseId}`,
        method: 'PUT',
        body: { image: params.body },
      }),
    }),
    deleteCourse: build.mutation({
      query: (courseId: string) => ({
        url: `course/${courseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Courses'],
    }),

    fetchCourses: build.query<ICourseFull[], Record<string, boolean | string | number | any[]> | undefined>({
      query: (criteria) => ({
        url: 'course',
        method: 'GET',
        params: criteria,
      }),
      providesTags: ['Courses'],
    }),

    fetchCourse: build.query({
      query: (courseId: string) => ({
        url: `course/${courseId}`,
        method: 'GET',
      }),
    }),

    reorderChapters: build.mutation({
      query: (data: { courseId: string; chaptersIds: string[] }) => ({
        url: `course/reorder-chapters/${data.courseId}`,
        method: 'PUT',
        body: data,
      }),
      // invalidatesTags: ['Chapters'],
    }),

    toggleIsInDev: build.mutation({
      query: (courseId: string) => ({
        url: `course/toggle-dev/${courseId}`,
        method: 'PUT',
      }),
    }),

    updateCourseImage: build.mutation<{ imageCover: string; imageLogo: string }, { id: string; image: string }>({
      query: ({ id, image }) => {
        return {
          url: `/course/update/image/${id}`,
          method: 'PUT',
          body: { image },
        };
      },
    }),

    // Chapters
    fetchChapter: build.query({
      query: (chapterId: string) => ({
        url: `chapter/${chapterId}`,
        method: 'GET',
      }),
    }),

    fetchChapters: build.query({
      query: (courseId: string) => ({
        url: `chapter/byCourse/${courseId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),

    createChapter: build.mutation({
      query: (dto) => ({
        url: 'chapter',
        method: 'POST',
        body: dto,
      }),
    }),

    toggleLockChapter: build.mutation<
      { locked: boolean; message: string; chapters: Pick<IChapterFull, '_id' | 'dependencies'>[] },
      { chapterId: string; spaceId?: string }
    >({
      query: ({ chapterId, spaceId }) => ({
        url: `chapter/toggle-lock/${chapterId}`,
        method: 'PUT',
        body: { spaceId },
        // invalidatesTags: ['Chapters'],
      }),
    }),

    // remove courseId from dto
    updateChapter: build.mutation({
      query: ({ chapterId, dto }) => ({
        url: `chapter/${chapterId}`,
        method: 'PUT',
        body: dto,
      }),
    }),

    reorderLessons: build.mutation({
      query: (data: any) => ({
        url: `chapter/reorder-lessons`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Lessons'],
    }),

    deleteChapter: build.mutation({
      query: (chapterId: string) => ({
        url: `chapter/${chapterId}`,
        method: 'DELETE',
      }),
    }),

    // Lessons
    fetchLesson: build.query({
      query: (lessonId: string) => ({
        url: `lesson/${lessonId}`,
        method: 'GET',
      }),
    }),

    fetchLessons: build.query({
      query: (chapterId: string) => ({
        url: `lesson/byChapter/${chapterId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Lessons'],
    }),

    updateLesson: build.mutation({
      query: ({ lessonId, dto }) => ({
        url: `lesson/${lessonId}`,
        method: 'PUT',
        body: dto,
      }),
    }),

    deleteLesson: build.mutation({
      query: (lessonId: string) => ({
        url: `lesson/${lessonId}`,
        method: 'DELETE',
      }),
    }),

    addLesson: build.mutation({
      query: (dto: any) => ({
        url: 'lesson',
        method: 'POST',
        body: dto,
      }),
    }),

    reorderSlides: build.mutation({
      query: (data: any) => ({
        url: 'lesson/reorder-slides',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Slides'],
    }),

    toggleLockLesson: build.mutation<
      { locked: boolean; message: string; lessons: Pick<ILessonFull, '_id' | 'dependencies'>[] },
      { lessonId: string; spaceId?: string }
    >({
      query: ({ lessonId, spaceId }) => ({
        url: `lesson/toggle-lock/${lessonId}`,
        method: 'PUT',
        body: { spaceId },
        invalidatesTags: ['Lessons'],
      }),
    }),

    // Slides
    fetchSlides: build.query({
      query: (lessonId: string) => ({
        url: `slide/byLessonId/${lessonId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Slides'],
    }),

    createSlide: build.mutation({
      query: (dto: any) => ({
        url: 'slide',
        method: 'POST',
        body: prepareCreateDto(dto),
      }),
      invalidatesTags: ['Slides'],
    }),

    updateSlide: build.mutation({
      query: (slide) => ({
        url: `slide/${slide._id}`,
        method: 'PUT',
        body: prepareUpdateDto(slide),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(spacesApi.util.invalidateTags(['Quests']));
      },
    }),

    duplicateSlide: build.mutation({
      query: (slide) => ({
        url: 'slide/duplicate',
        method: 'POST',
        body: prepareDuplicateDto(slide),
      }),
      invalidatesTags: ['Slides'],
    }),

    deleteSlide: build.mutation({
      query: (slideId: string) => ({
        url: `slide/${slideId}`,
        method: 'DELETE',
      }),
    }),

    updateSlideWidgets: build.mutation({
      query: ({ slideId, dto }) => ({
        url: `slide/${slideId}`,
        method: 'PUT',
        body: dto,
      }),
    }),

    deleteWidget: build.mutation({
      query: ({ slideId, dto }) => ({
        url: `slide/${slideId}`,
        method: 'PUT',
        body: dto,
      }),
    }),

    fetchRewards: build.query({
      query: ({ subject, id }: { subject: 'course' | 'chapter' | 'lesson' | 'slide'; id: string }) => ({
        url: `${subject}/full/${id}`,
        method: 'GET',
      }),
    }),

    addAttachment: build.mutation<ISlideFull, any>({
      query: ({ widgetId, form }) => ({
        url: `slide/attachment?widgetId=${widgetId}`,
        method: 'POST',
        body: form,
      }),
    }),

    deleteAttachment: build.mutation({
      query: ({ slideId, widgetId }) => ({
        url: `slide/attachment?widgetId=${widgetId}`,
        method: 'DELETE',
        body: { slideId },
      }),
    }),
    duplicateCourseLazy: build.mutation<{ success: boolean; result: any }, string>({
      query: (courseId) => {
        return {
          url: `/course/duplicate-lazy/${courseId}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Courses'],
    }),
    getCourseInfoBySlideId: build.query<{ _id: string } & Partial<ICourse>, string>({
      query: (slideId) => ({
        url: `course/by-child-id/${slideId}`,
      }),
    }),
  }),
});
