import { LANGS } from 'app/constants';

export enum ESpaceTag {
  AIAutomation = 'AI & Automation',
  BitcoinCryptocurrency = 'Bitcoin & Cryptocurrency',
  SocialMediaCourses = 'Social Media Courses',
  Web3 = 'Web3',
  Gaming = 'Gaming',
  DecentralisedFinance = 'Decentralised Finance',
  WebDevelopment = 'Web Development',
  DataScience = 'Data Science',
  ProgrammingCourses = 'Programming Courses',
  BlockchainProgramming = 'Blockchain Programming',
}

export interface ISpaceDesc {
  language: LANGS;
  title: string;
}

export interface ISpace {
  _id: string;
  users: ISpaceUser[];
  name: string;
  link: string;
  description: ISpaceDesc[];
  image: string;
  imageThumb: string;
  tags: ESpaceTag[];
  isVerified: boolean;
}

export interface ISpaceShort {
  name: string;
  imageThumb: string;
}

export interface IMySpacesResponseItem extends Omit<ISpace, 'users'> {
  users: IMySpaceRespUser[];
}

export interface ISpaceUser {
  roles: ESpaceRoles[];
  userId: {
    _id: string;
    nickname: string;
    avatar: string;
  };
  isSub?: boolean;
}

export interface IMySpaceRespUser extends Omit<ISpaceUser, 'userId'> {
  userId: string;
}

export enum ESpaceRoles {
  BASIC = 'BASIC',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}
