import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  // можно будет добавлять ещё что-то к этому цвету через solid 1px + {()=>}

  // цвет тела сайта
  body: '#FDFDFD',
  bodyRGB: '253, 253, 253',
  glassBg: '#e6eafa',
  // цвет текста, иконочек, и прочих мелких элементов
  fontColor: '#1C1D26',
  fontColorStrong: '#1C1D26',
  // цвета фона и плейсхолдера инпутов
  inputBg: '#FFFFFF',
  inputPlaceholder: '#505050',
  // цвет фона навбара и сайдбара
  background: 'rgba(211,215,229)',
  border: 'transparent',
  basicBorder: 'rgba(77, 99, 188, 0.25)',
  basicBG: 'rgba(230, 234, 250, 1)',
  basicBlue: '#627EEA',
  basicYellow: '#FFBF66',
  basicGreen: '#29AB88',
  basicPurple: '#CA47CE',
  basicRed: '#F14F63',
  basicCyan: '#95D8F5',
  // цвета фона тех элементов, которые должны иметь полупрозрачный фон
  backgroundTransparent: 'rgba(98, 126, 234, 0.15)',
  // fontBig: "rgb(42, 43, 55)",
  // textareaColor: "black",
  dark: false,
};

export const darkTheme = {
  // можно будет добавлять ещё что-то к этому цвету через + ' цвет'

  // цвет тела сайта
  body: '#1C1D26',
  bodyRGB: '28, 29, 38',
  glassBg: '#25262f',
  // цвет текста, иконочек, и прочих мелких элементов
  fontColor: '#AFB4B8',
  fontColorStrong: '#FFFFFF',
  // цвета фона и плейсхолдера инпутов
  inputBg: '#16161D',
  inputPlaceholder: '#AAAAAA',
  // цвет фона навбара и сайдбара
  background: '#32333F',
  border: 'rgba(255, 255, 255, 0.15)',
  basicBorder: 'rgba(255, 255, 255, 0.15)',
  basicBG: 'rgba(37, 38, 47, 1)',
  basicBlue: '#627EEA',
  basicYellow: '#FFBF66',
  basicGreen: '#29AB88',
  basicPurple: '#CA47CE',
  basicRed: '#F14F63',
  basicCyan: '#95D8F5',
  // цвета фона тех элементов, которые должны иметь полупрозрачный фон
  backgroundTransparent: 'rgba(255, 255, 255, 0.04)',
  // fontBig: "rgba(255, 255, 255, 0.1)",
  // textareaColor: "black",
  dark: true,
};

export const colorsLight = {
  dark: false,
  titleColor: '#111111',
  backgroundColor: '#ffffff',
  sidebarColor: 'rgba(0, 0, 0, 0.04)',
  sidebarIcon: '#111111',
  sidebarFont: '#111111',
  navbarColor: 'rgba(0, 0, 0, 0.04)',
  borderColor: 'rgba(0, 0, 0, 0.08)',
  mainSearchBackgroundColor: '#1C1D26',
  mainSearchFontColor: 'rgba(170, 170, 170, 0.65)',
};

export const colorsDark = {
  dark: true,
  titleColor: '#FFFFFF',
  backgroundColor: '#2A2B37',
  sidebarColor: 'rgba(255, 255, 255, 0.04)',
  sidebarIcon: '#AAAAAA',
  sidebarFont: '#AFB4B8',
  navbarColor: 'rgba(255, 255, 255, 0.04)',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  mainSearchBackgroundColor: '#1E1F2D',
  mainSearchFontColor: 'rgba(170, 170, 170, 0.65)',
};

export const GlobalStyles = createGlobalStyle`
  :root {
    --body-color: ${({ theme }) => theme.theme.body};
    --body-color--rgb: ${({ theme }) => theme.theme.bodyRGB};
    --glass-bg: ${({ theme }) => theme.theme.glassBg};
    --background-color: ${({ theme }) => theme.theme.background};
    --font-color: ${({ theme }) => theme.theme.fontColor};
    --font-color-strong: ${({ theme }) => theme.theme.fontColorStrong};
    --inputBg-color: ${({ theme }) => theme.theme.inputBg};
    --inputPlaceholder-color: ${({ theme }) => theme.theme.inputPlaceholder};
    --border-color: ${({ theme }) => theme.theme.border};
    --basic-border-color: ${({ theme }) => theme.theme.basicBorder};
    --basic-bg: ${({ theme }) => theme.theme.basicBG};
    --transbg-color: ${({ theme }) => theme.theme.backgroundTransparent};
    --red-color: ${({ theme }) => theme.theme.basicRed};
    
    --premium--rgb: 255, 191, 102;
    --basic-light: #1C1D26;
    --basic-light--rgb: 28, 29, 38;
    --basic-dark: #FFFFFF;
    --basic-dark--rgb: 255, 255, 255;
    --glass-light: #e6eafa;
    --glass-dark: #25262f;
    --basic-blue: #627EEA;
    --mighty-blue: #6487EF;
    --mighty-blue--rgb: 100, 135, 239;
    --mighty-gold--gradient: linear-gradient(122deg, #FADD9D -17.63%, #D48F52 55.96%), linear-gradient(90deg, #6487EF 0%, #53259B 125.56%), linear-gradient(282deg, rgba(255, 255, 255, 0.66) -0.2%, rgba(255, 255, 255, 0.10) 98.72%);
    --basic-light-bg: #D6DFFB;
    --basic-dark-bg: rgba(37, 38, 47, 1);
    --basic-shady-bg: linear-gradient(281.94deg, rgba(255, 255, 255, 0.116) -0.2%, rgba(255, 255, 255, 0) 98.72%);
    --basic-border: 1px solid var(--basic-border-color);
    --basic-hover-bg: rgba(175, 175, 175, 0.1);
    --mighty-gradient-new: radial-gradient(112.65% 134.04% at 6.69% -26.85%, rgba(149, 173, 244, 0.80) 0%, rgba(100, 136, 240, 0.00) 100%), linear-gradient(90deg, #6487EF 0%, #53259B 154.78%);
    --mighty-gradient: radial-gradient(30.97% 170.33% at 19.03% 18.67%, rgba(149, 173, 244, 0.8) 0%, rgba(100, 136, 240, 0) 100%), linear-gradient(77.35deg, rgba(12, 40, 161, 0.8) 0.89%, rgba(100, 135, 239, 0.8) 52.67%, rgba(83, 37, 155, 0.8) 128.34%);
    --white-bg: linear-gradient(0deg, #fff 0%, #fff 100%), linear-gradient(90deg, #6487ef 0%, #53259b 125.56%);
    --white-shadow: 11px 6px 12px 0 rgba(48, 49, 61, 0.01), 42px 26px 20px 0 rgba(48, 49, 61, 0.01), 65px 41px 22px 0 rgba(48, 49, 61, 0),
    0 0 56px 0 rgba(255, 255, 255, 0.25);
    
    --gray-bg: linear-gradient(76.31deg, rgb(244 248 255 / 0%) -1.86%, rgba(193, 204, 244, .1) 100.22%);
    --gold-bg: linear-gradient(180deg, #fadd9d 0%, #d48f52 100%);
    --silver-bg: linear-gradient(180deg, #b6c3df 0%, #fffbdf 100%);
    --bronze-bg: linear-gradient(180deg, #fabd9d 0%, #f7865f 100%);
    
    --basic-green--rgb: 41, 171, 136;

    // grades dark
    --grade-dark-color-common:     #FFFFFF;
    --grade-dark-color-uncommon:   #29AB88;
    --grade-dark-color-rare:       #95D8F5;
    --grade-dark-color-epic:       #CA47CE;
    --grade-dark-color-mythical:   #F14F63;
    --grade-dark-color-legendary:  #FFBF66;
    --grade-dark-color-divine:     #5CE7DE;
    
    --grade-dark-uncommon: linear-gradient(76.31deg, rgba(39, 42, 56, 0.8) 17.46%, rgba(34, 87, 82, 0.8) 107.31%);
    --grade-dark-rare: radial-gradient(60.13% 88.58% at 84.97% 21.43%, rgba(98, 126, 234, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%);
    --grade-dark-epic: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(228, 92, 231, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%);
    --grade-dark-mythical: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(241, 61, 90, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%);
    --grade-dark-legendary: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(255, 182, 93, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%);
    --grade-dark-divine: radial-gradient(60.13% 88.58% at 84.97% 21.43%, rgba(17, 109, 132, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%);

    // grades light
    --grade-light-color-common:    #8A8A8A;
    --grade-light-color-uncommon:  #00723B;
    --grade-light-color-rare:      #2687DB;
    --grade-light-color-epic:      #C947D3;
    --grade-light-color-mythical:  #F4556D;
    --grade-light-color-legendary: #FF8C00;
    --grade-light-color-divine:    #5CE7DE;
    
    // grades light button bg window
    --grade-light-color-common-bg:    rgba(216, 203, 205, 0.24);
    --grade-light-color-uncommon-bg:  rgba(41, 171, 136, 0.24);
    --grade-light-color-rare-bg:      rgba(98, 126, 234, 0.24);
    --grade-light-color-epic-bg:      rgba(202, 71, 206, 0.24);
    --grade-light-color-mythical-bg:  rgba(241, 79, 99, 0.24);
    --grade-light-color-legendary-bg: rgba(255, 191, 102, 0.24);
    
    --grade-light-uncommon: radial-gradient(63.4% 154.53% at 85.62% 22.96%, rgba(0, 255, 186, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.28) -1.86%, rgba(193, 204, 244, 0.052) 100.22%), rgba(0, 141, 73, 0.5);
    --grade-light-rare: radial-gradient(60.13% 88.58% at 84.97% 21.43%, rgba(98, 126, 234, 0.25) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.28) -1.86%, rgba(193, 204, 244, 0.052) 100.22%), rgba(98, 126, 234, 0.5);
    --grade-light-epic: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(186, 106, 190, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.28) -1.86%, rgba(193, 204, 244, 0.052) 100.22%), rgba(186, 107, 190, 0.5);
    --grade-light-mythical: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(241, 61, 90, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.28) -1.86%, rgba(193, 204, 244, 0.052) 100.22%), rgba(244, 85, 109, 0.5);
    --grade-light-legendary: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(255, 140, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.28) -1.86%, rgba(193, 204, 244, 0.052) 100.22%), rgba(255, 140, 0, 0.5);
    --grade-light-divine: radial-gradient(63.4% 154.53% at 85.62% 22.96%, rgba(0, 181, 230, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.28) -1.86%, rgba(193, 204, 244, 0.052) 100.22%), rgba(97, 218, 251, 0.5);

    --accent-text-dark:  #627EEA;
    --accent-text-light: #4D63BC;
    /* paddings */
    --padding-mobile: 20px;
    /* filters */
    --standard-blur: blur(12px);

    --toast-width: 343px;
    @media screen and (max-width: 992px) {
      --toast-width: 100%;
    } 
  }
  
  body {
    background-color: ${({ theme }) => theme.theme.body};
  }

  p, h1, h2, h3, h4, h5, h6, span {
    color: ${({ theme }) => theme.theme.fontColor};
  };

  *::-webkit-scrollbar {
    width: 8px;
  }
  
  *::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border: 1px solid ${({ theme }) => theme.theme.border};
    border-radius: 100px;
  }
  
  *::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  
  .tiny-sb {
    scrollbar-width: thin;
    scrollbar-color:  var(--font-color) rgba(218, 222, 225, 0.15);
  }

  .tiny-sb__dark {
    scrollbar-color: #1C1D26 rgba(12, 12, 12, 0.15);
  }

  .tiny-sb::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  .tiny-sb::-webkit-scrollbar-track {
    background-color: rgba(218, 222, 225, 0.15);
    width: 2px;
    border-radius: 100px;
  }

  .tiny-sb__dark::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .tiny-sb::-webkit-scrollbar-thumb {
    background-color: var(--font-color);
    border: none;
    border-radius: 100px;
  }

  .tiny-sb__dark::-webkit-scrollbar-thumb {
    background-color: #1C1D26;
  }

  .tiny-sb::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .avatar--round {
    border-radius: 100%;
  }

  .avatar--square {
    border-radius: 19% !important;
  }

  .filler {
    background: ${({ theme }) => theme.theme.backgroundTransparent};
  }

  .no-scroll-bar::-webkit-scrollbar {
    display: none
  }
  
  .mobile-h-scrollable {
    width: 100%;
    max-width: calc(100vw - var(--padding-mobile) * 2);
  }

  .mobile-padding {
    @media screen and (max-width: 992px) {
      padding-left: var(--padding-mobile);
      padding-right: var(--padding-mobile);
    }  
  }

  .mighty--golden {
    background: var(--mighty-gold--gradient);
    background-blend-mode: normal, normal, overlay;
    color: var(--font-color-strong);
  }
`;
