import { useEffect, useMemo, useState } from 'react';

export const useGetMiniAppInitData = () => {
  const [initData, setInitData] = useState<any>(null);

  useEffect(() => {
    if (window.Telegram.WebApp.initData) {
      const firstLayerInitData = Object.fromEntries(new URLSearchParams(window.Telegram.WebApp.initData));

      const initData: Record<string, string> = {};

      for (const key in firstLayerInitData) {
        try {
          initData[key] = JSON.parse(firstLayerInitData[key]);
        } catch {
          initData[key] = firstLayerInitData[key];
        }
      }

      setInitData(initData);
    }
  }, [window?.Telegram?.WebApp]);

  const startParam: string | null = useMemo(() => initData?.start_param || null, [initData]);

  return { startParam, initData };
};
