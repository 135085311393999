import { useEffect, useState } from 'react';
import { useGetIsAuth } from './useGetStoreGeneralData';
import { useLocation, useNavigate } from 'react-router-dom';
import getPartnerId from './usePartnerId';
import { ROUTES } from 'app/constants';

export const useNavigateUnauthUser = () => {
  const isAuth = useGetIsAuth();
  const [prevAuth, setPrevAuth] = useState(isAuth);
  const [wasNavigated, setWasNavigated] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const partnerId = getPartnerId();

  useEffect(() => {
    if (partnerId) return;
    if (isAuth && wasNavigated) {
      setWasNavigated(false);
    }
    if (!isAuth && !!prevAuth && !wasNavigated) {
      navigate(ROUTES.AUTH, { state: { prev: pathname } });
      setWasNavigated(true);
      setPrevAuth(false);
    }
    if (!isAuth && pathname === '/' && !prevAuth) {
      console.log('Should not fire');
      navigate(ROUTES.MAIN);
    }
  }, [isAuth, wasNavigated, pathname, prevAuth]);
};
